
            @import "@/assets/sass/vars.portal.scss";
          







































































































































































.v-picture-upload {
    &__input {
        text-align: center;
        overflow: hidden;
    }
    &__button {
    }
}
